import { Constant } from "src/app/constants/constant";
import { EcryptServiceService } from "src/app/services/ecrypt-service.service";

export const environment = {
  production: true,
  serverAPI: 'https://www.mahakaviherbals.com/ecom-gateway/ecom-svc-catalog-1.0-SNAPSHOT',
  serverAPI2: 'https://www.mahakaviherbals.com/ecom-gateway/ecom-svc-core-1.0-SNAPSHOT',
  serverAPI3: 'https://www.mahakaviherbals.com/ecom-gateway/ecom-svc-sales-1.0-SNAPSHOT',
  serverAPI4: 'https://www.mahakaviherbals.com/ecom-gateway/ecom-svc-admin-1.0-SNAPSHOT',
  ImageAPI: 'https://www.mahakaviherbals.com/ecom-gateway/ecom-svc-core-1.0-SNAPSHOT/api/core/download?fileName=catalog/product/small_image/200x170/',
  ImageAPI1: 'https://www.mahakaviherbals.com/ecom-gateway/ecom-svc-core-1.0-SNAPSHOT/api/core/download?fileName=catalog/product/image/600x600/',
  commonImageApi: 'https://www.mahakaviherbals.com/ecom-gateway/ecom-svc-core-1.0-SNAPSHOT/api/core/download?fileName=',
  youtubeUrl: 'https://www.youtube.com/embed/',
  thumnail: 'https:/www.mahakaviherbals.com/ecom-gateway/ecom-svc-core-1.0-SNAPSHOT/api/core/download?fileName=catalog/product/thumbnail/75x75/',
  commonImageApiNew:'https://www.mahakaviherbals.com/ecom-gateway/ecom-svc-core-1.0-SNAPSHOT/api/core/download/common?fileName=',
  firebase: getConfig(),
};
function getConfig() {
  let config;
  let finalData=localStorage.getItem("m-d");
  let encryptService = new EcryptServiceService();
  let obj=null;
  if(finalData!=null){
      obj = JSON.parse(encryptService.decryptionAES(finalData));
  }

   if(obj!=null){
        
    config = {
      apiKey: obj['apiKey'],
      authDomain: obj['authDomain'],
      projectId: obj['projectId'],
      storageBucket: obj['storageBucket'],
      messagingSenderId: obj['messagingSenderId'],
      appId: obj['appId']
    };

  }else{

   
    let request = new XMLHttpRequest();
    try {
      
    
     var url="https://"+Constant.REFERER+'/ecom-gateway/ecom-svc-catalog-1.0-SNAPSHOT/api/catalog/siteData' 
     //var url = 'http://localhost:8086/ecom-gateway/ecom-svc-catalog-1.0-SNAPSHOT' + "/api/catalog/siteData"
  
      request.open('GET', url, false);  // `false` makes the request synchronous
      request.setRequestHeader('Content-Type', 'application/json');
  
      request.setRequestHeader(Constant.DEVICE, Constant.DEVICE_TYPE);
  
      request.setRequestHeader(Constant.ENTERPRISE_SITE, Constant.REFERER);
  
  
      request.send(null);
  
     // console.log(request);
  
      if (request.status === 200) {
      //  console.log(request.responseText);
        config = request.responseText;
        obj = JSON.parse(request.responseText);
        let valueNew = this.ecryptService.encryptionAES(JSON.stringify(obj));
                
        localStorage.setItem("m-d",valueNew);
        config = {
          apiKey: obj['apiKey'],
          authDomain: obj['authDomain'],
          projectId: obj['projectId'],
          storageBucket: obj['storageBucket'],
          messagingSenderId: obj['messagingSenderId'],
          appId: obj['appId']
        };
      }
  
    } catch (e) {
      console.error('environment:getConfig: unable to get api key : ', e);
    }
    
  }


  return config;
}